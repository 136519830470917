<script setup lang="ts">
const { id } = defineProps<{
  id: number
}>()

const video = ref()
const player = ref(null)

const { onLoaded } = useScriptYouTubePlayer()

onLoaded(async ({ YT }) => {
  // we need to wait for the internal YouTube APIs to be ready
  const YouTube = await YT
  await new Promise<void>((resolve) => {
    if (typeof YT.Player === 'undefined')
      YouTube.ready(resolve)
    else
      resolve()
  })
  // load the API
  player.value = new YT.Player(video.value, {
    videoId: id,
  })
})
</script>

<template>
  <div
    ref="video"
    class="aspect-video w-full h-full rounded"
  />
</template>
