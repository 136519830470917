<script setup lang="ts">
const { data, status } = useLazyFetch('/api/banners')
</script>

<template>
  <div
    v-if="status === 'pending'"
    class="relative"
  >
    <USkeleton
      class="w-full bg-gray-200 rounded-none h-[150px]"
    />
    <div class="absolute inset-0 flex items-center justify-center">
      <UIcon
        class="text-gray-950 size-8 animate-pulse"
        name="hugeicons:image-composition"
      />
    </div>
  </div>
  <div
    v-else
  >
    <NuxtLink :to="data?.to">
      <NuxtImg
        fit="cover"
        :src="data?.src"
        :width="1200"
        :height="150"
        :quality="95"
        :alt="data?.alt"
      />
    </NuxtLink>
  </div>
</template>
