<script setup lang="ts">
import type { Block } from '@/types/blocks'

const { innerHTML } = defineProps<Block>()

// Wydobycie href z linku
const hrefRegex = /<a [^>]*href="([^"]+)"[^>]*>/
const hrefMatch = innerHTML.match(hrefRegex)
const linkHref = hrefMatch ? hrefMatch[1] : undefined

// Wydobycie tekstu z linku
const textRegex = />([^<]+)<\/a>/
const textMatch = innerHTML.match(textRegex)
const linkText = textMatch ? textMatch[1]?.trim() : undefined

const targetRegex = /<a [^>]*target="([^"]+)"[^>]*>/
const targetMatch = innerHTML.match(targetRegex)
const linkTarget = targetMatch ? targetMatch[1] : undefined
</script>

<template>
  <UButton
    size="xl"
    :target="linkTarget"
    :to="linkHref"
    :label="linkText"
  />
</template>
