<script setup lang="ts">
import type { BlockCoreEmbed } from '@/types/blocks'

const { attrs } = defineProps<BlockCoreEmbed>()

const tweetId = computed(() => {
  const first = attrs.url.split('status/')[1]
  const split = first?.split('?')

  if (split?.[0]) return split[0]

  return first
})

const youtubeId = attrs.url.includes('youtube.com') ? attrs.url.split('=')[1] : attrs.url.split('youtu.be/')[1]
const spotifyId = attrs.url.split('episode/')[1]
</script>

<template>
  <div v-if="attrs.providerNameSlug === 'twitter'">
    <BlockCoreEmbedTwitter :id="tweetId" />
  </div>

  <div v-if="attrs.providerNameSlug === 'youtube'">
    <BlockCoreEmbedYoutube :id="youtubeId" />
  </div>

  <div v-if="attrs.providerNameSlug === 'spotify'">
    <div class="aspect-auto">
      <iframe
        style="border-radius: 12px"
        width="100%"
        height="152"
        frameborder="0"
        allowfullscreen
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        :src="`https://open.spotify.com/embed/episode/${spotifyId}`"
      />
    </div>
  </div>
</template>
